import { List, ListItem, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Translation } from "./Translation";

// display help within a dialog
export default function Help({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{Translation.help.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Translation.help.body}
            <List>
              {Translation.help.tips.map((e, index) => {
                return (
                  <ListItem sx={{ pl: "0" }} key={"help-" + index}>
                    <ListItemText
                      primary={
                        <>
                          <b>{e.primary}</b>
                        </>
                      }
                      secondary={e.secondary}
                    />
                  </ListItem>
                );
              })}
            </List>
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{Translation.help.action}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
