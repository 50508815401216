import { ListItemText, Switch } from "@mui/material";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { useContext, useState } from "react";
import { NotificationContext } from "../context/NotificationContext";
import { Translation } from "./Translation";

// element to activate storing of all prompts
export default function StorePrompts() {
  const { configuration, setConfiguration } = useContext(ConfigurationContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const [store, setStore] = useState(configuration.storePrompts);

  // toggle between storing/ not storing the data
  const toggleStorePrompts = () => {
    setConfiguration({ ...configuration, storePrompts: !store });
    setStore(!store);

    setNotification({
      ...notification,
      type: "success",
      show: true,
      message:
        !store === true
          ? Translation.storePrompts.active
          : Translation.storePrompts.inactive,
      loading: false,
    });
  };

  return (
    <>
      <ListItemText
        id="store-prompts"
        primary={Translation.storePrompts.title}
      />
      <Switch
        edge="end"
        onChange={() => toggleStorePrompts()}
        checked={store}
        inputProps={{
          "aria-labelledby": "store-prompts",
        }}
      />
    </>
  );
}
