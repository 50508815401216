import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

// check if the user is authenticated otherwise forward to login page
export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);

  if (user === undefined || user === null || user.authenticated === false) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
