import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../context/NotificationContext";
import { UserContext } from "../context/UserContext";
import { Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import StorePrompts from "./StorePrompts";
import axios from "axios";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { Translation } from "./Translation";
import updateUserContext from "../helper/updateUserContext";

// after how many questions the feedback dialog should pop-up
const numberOfSearchs = 5;

// dialog to clear the message history and create a new session
export default function RatingDialog({ open, setOpen }) {
  const { notification, setNotification } = useContext(NotificationContext);
  const { user, setUser } = useContext(UserContext);
  const { configuration } = useContext(ConfigurationContext);
  const [questions, setQuestions] = useState(Translation.rating.questions);

  // close dialog
  const handleCancel = () => {
    setOpen(false);
  };

  // store the given feedback
  const giveFeedback = () => {
    setOpen(false);
    axios
      .post(
        "/api/feedback",
        {
          id: user.id,
          business: user.business,
          feedback: questions,
          storePrompts: configuration.storePrompts,
        },
        {
          headers: {
            "Custom-Auth-Key": user.token,
          },
        }
      )
      .then(() => {
        // store feedback for current session and via local storage
        updateUserContext(user, setUser, { feedbackGiven: true });

        setNotification({
          ...notification,
          type: "success",
          show: true,
          message: Translation.rating.success,
        });
        setOpen(false);
      })
      .catch(() => {
        setNotification({
          ...notification,
          type: "error",
          show: true,
          message: Translation.rating.error,
        });
      });
  };

  useEffect(() => {
    // if user has reached min. number of questions and hasn't given feedback yet, ask for it
    if (user.counter === numberOfSearchs && user.feedbackGiven === null) {
      setOpen(true);
      setUser({ ...user, feedbackGiven: false });
    }
  }, [user, setUser, setOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translation.rating.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {questions.map((entry, index) => {
              return (
                <>
                  <Typography key={`rating-${index}`} component="legend">
                    {entry.question}
                  </Typography>
                  <Box
                    sx={{
                      width: 800,
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={entry.value}
                      onChange={(event, newValue) => {
                        setQuestions(
                          questions.map((data) => {
                            if (data.id === entry.id) {
                              return { ...data, value: newValue };
                            } else {
                              return data;
                            }
                          })
                        );
                      }}
                      onChangeActive={(event, newHover) => {
                        setQuestions(
                          questions.map((data) => {
                            if (data.id === entry.id) {
                              return { ...data, hover: newHover };
                            } else {
                              return data;
                            }
                          })
                        );
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {(entry.value !== null || entry.hover !== null) && (
                      <Box sx={{ ml: 2 }}>
                        {
                          Translation.rating.labels[
                            questions.filter((e) => e.id === entry.id)[0]
                              .hover !== -1
                              ? questions.filter((e) => e.id === entry.id)[0]
                                  .hover
                              : entry.value
                          ]
                        }
                      </Box>
                    )}
                  </Box>
                </>
              );
            })}

            {configuration.storePrompts === false ? <StorePrompts /> : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={giveFeedback} variant="contained">
            {Translation.rating.submit}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
