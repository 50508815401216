import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import Models from "../components/Models";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { Fragment, useContext } from "react";
import StorePrompts from "./StorePrompts";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Translation } from "./Translation";

// configuration panel to change the used model and if the data can be stored
export default function Configuration() {
  const { configuration } = useContext(ConfigurationContext);

  return (
    <>
      <List className="selectModel">
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="h6" gutterBottom>
                {Translation.configuration.title}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={<Models />} />
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography variant="caption" display="block" gutterBottom>
                {Translation.configuration.details}
              </Typography>
            }
            secondary={
              <Fragment>
                <Typography
                  sx={{ display: "block" }}
                  component="span"
                  variant="body1"
                  gutterBottom
                >
                  {configuration.model.description}
                </Typography>
              </Fragment>
            }
            sx={{ whiteSpace: "normal" }}
          />
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <ListItem className="storePrompts">
          <StorePrompts />
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <ListItem className="addUseCase">
          <Button
            size="small"
            href={Translation.configuration.url}
            target="_blank"
            variant="text"
            startIcon={<TipsAndUpdatesOutlinedIcon />}
          >
            {Translation.configuration.addUseCase}
          </Button>
        </ListItem>
        <ListItem className="getHelp">
          <Button
            size="small"
            href={Translation.configuration.contact.target}
            target="_blank"
            variant="text"
            aria-label={Translation.configuration.contact.help}
            startIcon={<MailOutlineIcon />}
          >
            {Translation.configuration.contact.title}
          </Button>
        </ListItem>
      </List>
    </>
  );
}
