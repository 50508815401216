import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";

let tutorialStep = 1;

export let Translation = {
  title: "GoChat",
  disclaimer: "Bitte denke daran, keine sensiblen Daten einzutragen",
  claim: {
    main: "Künstliche Intelligenz nutzen - Zukunft erleben",
    sub: "Einfach & sicher: Mit GoChat modernste Technologie nutzen",
    teaser:
      "Mit GoChat kannst du die Technologie von ChatGPT sicher und einfach nutzen. Über unser Chatinterface kannst du mit einer KI interagieren, welche Dir auf Grundlage deiner Eingaben (Prompts) passende Antworten generiert.",
    long: (
      <>
        GoChat hat keinen Zugriff auf das Internet - tagesaktuelle
        Informationen sind somit nicht verfügbar. Analog zu ChatGPT von OpenAI
        endet das Wissen von GoChat zu einem bestimmten Zeitpunkt. Ebenso sind keine
        Konzern-spezifischen Informationen hinterlegt.
        <br />
        Anders als bei ChatGPT von OpenAI werden bei GoChat keine Eingaben
        gespeichert. GoChat erhält die GPT-Technologie über die Dienste von
        Microsoft Azure bereitgestellt.
      </>
    ),
    continue: "Mehr erfahren...",
  },
  TutorialDialg: {
    header: "Willkommen bei GoChat",
    body: "Möchtest du eine kurze Einführung starten, um dich mit den wesentlichen Funktionen und Besonderheiten von GoChat vertraut zu machen?",
    confirm: "Einführung starten",
    cancel: "Abbrechen",
  },
  ClearDialog: {
    header: "Chatverlauf löschen?",
    body: "Soll der gesamte Chatverlauf gelöscht werden? Dies kann nicht rückgängig gemacht werden!",
    delete: "Löschen",
    cancel: "Abbrechen",
    notification: "Chatverlauf wurde gelöscht",
  },
  ConfirmDialog: {
    header: "Chatverlauf löschen?",
    body: "Beim Wechsel der Persona empfiehlt es sich den gesamten Chatverlauf zu löschen. Dies kann jedoch nicht rückgängig gemacht werden! Soll der Chatverlauf gelöscht werden?",
    delete: "Löschen",
    cancel: "Behalten",
    help: {
      delete: "Chatverlauf löschen",
      cancel: "Chatverlauf nicht löschen",
    },
  },
  PromptDialog: {
    header: "Beispiel-Prompt ausprobieren",
    body: "Befülle folgende Felder mit deinen spezifischen Informationen",
    try: "Ausprobieren",
    cancel: "Abbrechen",
    error: "Bitte alle Felder ausfüllen!",
  },
  intro: [
    {
      position: "center",
      open: false,
      content: (
        <p>
          GoChat: Künstliche Intelligenz erleben - einfach und sicher
          <ul>
            <li>
              GoChat nutzt ChatGPT von OpenAI über Microsoft Azure. Über
              ein Chatinterface kannst du mit der KI interagieren, welche
              Dir auf Grundlage deiner Eingaben (Prompts) passende Antworten
              generiert.
            </li>
            <li>
              Wichtig: GoChat hat keinen Zugriff auf das Internet oder
              Konzern-spezifische Informationen.
            </li>
          </ul>
          Im Folgenden erhältst du eine kurze Einführung in die wichtigsten
          Funktionselemente. Tipp: Du kannst die Einführung mit den Pfeiltasten steuern!
        </p>
      ),
    },
    {
      selector: ".MuiInput-input",
      open: false,
      content:
        "Über das Textfeld am unteren Bildschirmrand kannst du mit dem Bot interagieren. Die Interaktion erfolgt über Chat-Nachrichten, wie man sie von z. B. Messenger-Apps wie WhatsApp oder Signal kennt. Die Nutzereingaben im Rahmen einer Chat-Nachricht nennen wir im Kontext von GoChat „Prompts“. Die Prompts „provozieren“ das KI-Modell hinter der GoChat-Persona, Antworten zu finden, die bestmöglich zur Prompt passen.",
    },
    {
      selector: ".uploadButton",
      open: false,
      content:
        "Über diesen Button kannst du eine Bilddatei (jpg/png) oder ein PDF Dokument auswählen und hochladen. Mit der Prompt kannst du die KI dann gezielt mit der Datei arbeiten lassen.",
    },
    {
      selector: ".sentButton",
      open: false,
      content:
        "Mit dem Button kannst du die Prompt absenden.",
    },
    {
      selector: ".burgerMenu",
      position: "right",
      open: false,
      content:
        "Über diesen Button kannst du die seitliche Menüleiste aus- und einklappen.",
    },
    {
      selector: ".selectModel",
      position: "right",
      open: true,
      content:
        "In diesem Panel findest du unterschiedliche Personas zur Auswahl. Die Textbox unter den Personas gibt einen kurzen Überblick über die jeweiligen Fähigkeiten und Einsatzzwecke. Hier geht es besonders darum den Usern die Möglichkeit aufzuzeigen, wie man mit der (meist für User nicht sichbaren) Systemprompt die Antworten beeinflussen kann: positiv, wie auch negativ.",
    },
    {
      selector: ".storePrompts",
      position: "right",
      open: true,
      content:
        "Für die Produktverbesserung kannst du über den Schieber auf freiwilliger Basis deine Eingaben übermitteln.",
    },
    {
      selector: ".addUseCase",
      position: "right",
      open: true,
      content:
        "Der Link „Use Case Idee eintragen“ führt dich direkt auf eine Wiki-Seite, in der du deine Ideen für GPT-Anwendungsmöglichkeiten übermitteln kannst.",
    },
    {
      selector: ".getHelp",
      position: "right",
      open: true,
      content:
        "Über den Link „Hilfe bekommen“ kannst du dich per E-Mail direkt an die Entwickler wenden.",
    },
    {
      selector: ".naviMenu",
      position: "center",
      open: false,
      content:
        "Über die Buttons stehen dir weitere Funktionen zur Verfügung. Fahre mit der Maus über die Icons, um weitere Informationen zu erhalten. Einige Buttons werden nur unter bestimmten Bedinungen angezeigt. Der Button „LÖSCHEN“ wird z.B. nur angezeigt, wenn du bereits einen Dialog begonnen hast."
    },
  ],
  tutorial: {
    initMessages: {
      question: "Hier steht deine Nachricht (Prompt) an GoChat",
      answer: "Hier steht die Antwort auf deine Nachricht (Prompt)",
    },
    steps: [
      {
        number: tutorialStep,
        title: "Einleitung",
        content:
          "In dieser Schritt-Für-Schritt-Anleitung führen wir dich durch die Möglichkeiten von GoChat. Beginnend mit einfachen Eingaben, werden wir das Konzept der Personas und erweiterte Eingabekonzepte, sogenanntes Prompt Engineering, erklären.",
        examples: [],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Erste Schritte",
        content:
          "Wie vielleicht aus anderen Produkten bekannt, erfolgt die Bedienung von GoChat ebenfalls über eine Chat-Oberfläche. In dem unteren Feld können Befehle an GoChat eingetragen und mit Klick auf das „Pfeil“-Symbol oder Druck auf die Enter-Taste abgesendet werden. Wir nennen diese Befehle innerhalb von GoChat „Prompts“ oder „Eingaben“.",
        examples: [],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "5+5",
        content:
          "GoChat nimmt diese Eingabe (Prompt) und erzeugt eine dazu passende Antwort, welche im mittleren Fenster angezeigt wird. Probiere es direkt aus: Tippe 'Was ist 5+5?' in das Eingabefeld und sende deine Eingabe über das Pfeilsymbol oder die Entertaste ab.",
        examples: ["Was ist 5+5?"],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Analogien",
        content:
          "GoChat kann viele verschiedene Aufgabenstellungen bearbeiten. Lass dir beispielsweise Analogien zu Wörtern erzeugen:",
        examples: [
          "Fragen sind wie Pfeile, da sie...",
          "Wahrheit verhält sich zu Wasser wie…",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Kreativität",
        content: "Lass Dir Ideen zu einem Vorhaben generieren.",
        examples: [
          "Erzeuge acht Fragen für mein Interview mit einem Science Fiction-Autor",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Mit Texten arbeiten",
        content:
          "GoChat kann auch mit bereits existierenden Texten arbeiten. Setze hierfür einfach deine Anweisung vor den Text, den du verarbeiten möchtest.",
        examples: [
          "Worum geht es in folgendem Gedicht: \nIm Nebel ruhet noch die Welt \nNoch träumen Wald und Wiesen \nBald siehst du, wenn der Schleier fällt \nDen blauen Himmel unverstellt \nHerbstkräftig die gedämpfte Welt \nIn warmem Golde fließen.",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Personas I",
        content:
          "Je nach Aufgabenstellung, ist es sinnvoll einem Chatbot eine „Persona“ zuzuweisen. Eine Persona ist eine als Fließtext ausgestaltete Rollenbeschreibung, die das nachfolgende Antwortverhalten des Chatbots steuert.",
        examples: [],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Personas II",
        content:
          "Wir haben eine Reihe an Personas vorkonfiguriert, welche du im Menü am linken Bildschirmrand auswählen kannst. Nach Auswahl einer Persona erscheint im Textfeld unter dem Menü eine kurze Beschreibung. GoChat ist als Allzweckpersona standardmäßig vorausgewählt.",
        examples: [],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Personas III",
        content:
          "Personas können nicht nur systemseitig konfiguriert, sondern auch durch den Nutzer vergeben werden. Dafür wird die Persona einfach der eigentlichen Eingabe vorangestellt. Statt „Was ist der Kölner Dom?“, wäre eine entsprechende Eingabe mit Persona „Du bist ein Bot, der alle Benutzereingaben in Emojis übersetzt. Was ist der Kölner Dom?“",
        examples: [
          "Du bist ein Bot, der alle Benutzereingaben in Emojis übersetzt. Du antwortest nur in Emojis und benutzt keine Worte. Was ist der Kölner Dom?",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Personas IV",
        content:
          "Probiere es aus und lass unsere GoChat-Persona (linkes Menü) die Aufforderung „Erstelle 9 Fragen für ein Interview mit einem Filmstar“ als Rechtsanwalt für Arbeitsrecht und danach als Rechtsanwalt für Wettbewerbsrecht beantworten. Du siehst, dass du in beiden Fällen 9 Interviewfragen erhältst, welche sich jedoch deutlich in ihren Frageinhalten unterscheiden. Vergiss nicht vor der Eingabe die passende Persona (Blanko-Bot) auszuwählen.",
        examples: [
          "Du bist Rechtsanwalt für Arbeitsrecht. Erstelle 9 Fragen für ein Interview mit einem Filmstar",
          "Du bist Rechtsanwalt für Wettbewerbsrecht. Erstelle 9 Fragen für ein Interview mit einem Filmstar",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Chain-Of-Thought-Prompting",
        content:
          "Die Antwortgüte von GPT-basierten Produkten lässt sich in der Regel durch geschicktes Design der Prompts verbessern. Für komplexere Fragestellung hat sich das „Chain-Of-Thought“-Prompting als gute Strategie erwiesen. Hierbei wird der Bot angewiesen ein Problem „Schritt-für-Schritt“ zu lösen.",
        examples: [
          "Johann passt auf 10 Hunde auf. Jeder Hund benötigt 0.5 Stunden Pflege täglich. Wie viele Stunden verbringt er wöchentlich mit der Pflege der Hunde? Lass uns Schritt für Schritt vorgehen.",
        ],
        last: false,
      },
      {
        number: tutorialStep++,
        title: "Hinweise",
        content: (
          <>
            Zum Abschluss der Anleitung noch ein paar Hinweise zu GoChat:{" "}
            <ul>
              <li>
                Bitte teile{" "}
                <b>
                  keine Geschäftsgeheimnisse, personenbezogene oder
                  personenbeziehbare Daten
                </b>{" "}
                mit GoChat.
              </li>
              <li>
                GoChat kann und wird <b>falsche Ergebnisse</b> zurückliefern,
                insbesondere kritisch sind solche, die auf den ersten (und auch
                zweiten) Blick <b>plausibel wirken</b>.
              </li>
              <li>
                GoChat wird ggf. auch Links und Literaturbelege{" "}
                <b>frei erfinden</b>
                und nicht als Erfindung kenntlich machen.
              </li>
              <li>
                Wir <b>freuen uns über Feedback</b> und insbesondere auch über
                deine Ideen bzgl. Use Cases, welche du uns über den Button im
                linken Menü mitteilen kannst.
              </li>
            </ul>
          </>
        ),
        examples: [],
        last: true,
      },
    ],
    question:
      "Möchtest du zum Abschluss eine kurze Schritt-für-Schritt-Anleitung durchlaufen?",
    start: "Anleitung starten",
    title: "Schritt-für-Schritt-Anleitung",
    next: "Weiter",
    back: "Zurück",
    finish: "Abschließen",
    examples: "Beispiele",
    example: "Beispiel",
    tooltip: "Hier klicken um das Beispiel auszuprobieren",
    done: "Herzlichen Glückwunsch - du hast alle Schritte erfolgreich absolviert. Wie fandest du diese Anleitung?",
    positive: {
      button: "Top",
      help: "Die Schritt-für-Schritt-Anleitung als hilfreich melden",
    },
    negativ: {
      button: "Flop",
      help: "Die Schritt-für-Schritt-Anleitung als nicht hilfreich melden",
    },
    feedback: {
      button: "Kommentar",
      help: "Einen Kommentar zur Schritt-für-Schritt-Anleitung schicken",
      target:
        "mailto:dm2@gothaer.de?subject=[GoChat]: Kommentar zur Schritt-für-Schritt-Anleitung",
    },
    rating: {
      success:
        "Vielen Dank! Dein Feedback zur Schritt-für-Schritt-Anleitung wurde gespeichert",
      error:
        "Es ist ein Fehler aufgetreten - dein Feedback wurde leider nicht gespeichert!",
    },
    reset: "Wiederholen",
  },
  navigation: {
    config: {
      label: "Menü",
      help: "Das Einstellungsmenü links anzeigen",
    },
    feedback: {
      label: "Feedback",
      help: "Ein kurzes Feedback zu GoChat abgeben",
    },
    clear: {
      label: "Löschen",
      help: "Den bisherigen Chatverlauf und ggfs. hochgeladene Dokumente löschen",
    },
    tutorial: {
      label: "Anleitung",
      help: "Schritt-für-Schritt-Anleitung starten",
    },
    help: {
      label: "Hilfe",
      help: "Eine kurze Hilfe mit Tipps & Tricks zur Nutzung anzeigen",
    },
    idea: {
      label: "Idee melden",
      help: "Eine Idee für einen neuen Use Case Idee melden",
    },
    tour: {
      label: "Einführung",
      help: "Die Einführungstour anzeigen",
    },
    close: {
      label: "Schließen",
      help: "Das Einstellungsmenü links schließen",
    },
    generator: {
      label: "Zurück",
      help: "Zurück zur Startseite",
    },
  },
  submitQuestion: {
    empty: "Bitte eine Prompt eintragen!",
    error: "Es ist ein Fehler aufgetreten!",
    tooManyPages: "Das PDF Dokument hat zu viele Seiten! Erlaubt sind maximal 50 Seiten.",
    configError: "Es ist ein Fehler mit dem Modell aufgetreten! Versuche bitte ein anderes.",
    rateLimit:
      "Es ist gerade viel los und deine Eingabe konnte nicht verarbeitet werden. Bitte versuche es gleich nochmal!",
    openAiApiUnavailable:
      "Der Microsoft Azure OpenAI Dienst ist gerade nicht erreichbar. Bitte versuche es gleich nochmal!",
  },
  search: {
    field:
      "Gebe hier deine Prompt ein. Zum Absenden nutze Enter oder klicke auf den Pfeil rechts. Mit der Copy-Paste-Funktion kannst du auch Bilder direkt aus dem Zwischenspeicher einfügen.",
    button: "Auf diesen Button klicken um die Prompt abzuschicken",
    upload: "Datei hochladen",
    delete: "Datei löschen",
    wrongFileType: "Zugelassen Dateiformate sind JPG, PNG und PDF.",
  },
  rating: {
    labels: {
      1: "Sehr schlecht",
      2: "Schlecht",
      3: "In Ordnung",
      4: "Gut",
      5: "Sehr gut",
    },
    questions: [
      { id: 1, question: "Relevanz der Antworten", value: null, hover: -1 },
      {
        id: 2,
        question: "Inhaltliche Qualität der Antworten",
        value: null,
        hover: -1,
      },
      {
        id: 3,
        question: "Verständlichkeit der Antworten",
        value: null,
        hover: -1,
      },
      {
        id: 4,
        question:
          "Ich kann mir vorstellen, diese Technologie in meinem Arbeitsalltag nutzbringend einzusetzen",
        value: null,
        hover: -1,
      },
    ],
    title:
      "Bitte gebe uns ein kurzes Feedback und schalte weitere Personas frei!",
    submit: "Abschicken",
    success: "Vielen Dank! Dein Feedback wurde gespeichert",
    error:
      "Es ist ein Fehler aufgetreten - dein Feedback wurde leider nicht gespeichert!",
  },
  result: {
    question: "Du",
    answer: "KI-Assitent",
    success: "Danke! Dein Feedback wurde gespeichert!",
    copy: {
      title: "Den oben stehenden Code in die Zwischenablage kopieren",
      action: "Antwort in die Zwischenablage kopieren",
      code: "Der Code wurde in die Zwischenablage kopiert",
      text: "Die Antwort wurde in die Zwischenablage kopiert",
    },
    feedback: {
      negativ: "Antwort als negativ bewerten",
      positiv: "Antwort als positiv bewerten",
    },
  },
  configuration: {
    title: "Einstellungen",
    details: "Hinweis zu der gewählten Persona",
    addUseCase: "Use Case Idee eintragen",
    url: "https://netze.gothaer.de/pages/viewpage.action?pageId=449723609",
    contact: {
      title: "Hilfe bekommen",
      help: "Auf diesen Button klicken um eine E-Mail zu schreiben um Hilfe zu bekommen bzw. Fragen zu stellen",
      target: "mailto:dm2@gothaer.de?subject=[GoChat]: Hilfe zu ...",
    },
  },
  help: {
    title: "Hilfe",
    body: "Hier ein paar Tipps wie du gute Prompts schreibst:",
    tips: [
      {
        primary: "Formuliere genau!",
        secondary:
          "Lasse so wenig Spielraum für Interpretationen, wie möglich.",
      },
      {
        primary: "Formuliere beschreibend!",
        secondary: "Verwende Analogien und/oder Beispiele.",
      },
      {
        primary: "Erkläre, was du meinst!",
        secondary:
          "Manchmal musst du dem Chatbot erklären, was du erwartest. Gib Hinweise.",
      },
      {
        primary: "Die Reihenfolge ist wichtig!",
        secondary:
          "Die Reihenfolge, in der du dem Chatbot Informationen bereitstellst, kann sich auf die Ausgabe auswirken. Bei der Ausgabe kann es einen Unterschied ausmachen, ob du Anweisungen vor deinen Inhalten („fasse folgendes zusammen…“) oder danach („fasse das Vorhergehende zusammen…“) platzierst.",
      },
      {
        primary: "Gib dem Modell eine Möglichkeit zum Abbruch!",
        secondary:
          "Es kann manchmal hilfreich sein, dem Chatbot einen alternativen Pfad zu geben, wenn er die zugewiesene Aufgabe nicht ausführen kann. Wenn du beispielsweise eine Frage über einen Textabschnitt stellst, kannst du etwas wie „Antworte mit ‚nicht gefunden‘, wenn die Antwort nicht vorhanden ist“ einfügen. Dadurch verhinderst du, dass das Modell falsche Antworten generiert.",
      },
      {
        primary: "Gib dem Chatbot eine Rolle/Persona",
        secondary:
          "Für einige Prompts kann es sinnvoll sein, dem Modell in der Prompt eine Rolle zu zuweisen, aus welcher das Modell die Prompt beantworten soll. Beispiel: „du bist ein Experte für XYZ, erkläre das Thema XYZ einem Neuling im Bereich XYZ.“",
      },
      {
        primary: "Lass den Chatbot in Teilschritten arbeiten",
        secondary:
          "Die Performance des Chatbots bei komplexen Anliegen verbessert sich, wenn der Bot ein Problem in Teilprobleme zerlegt. Entsprechende Eingaben nennt man 'chain-of-thought'-Prompts. Eine einfache Möglichkeit dieses Verhalten zu provozieren besteht darin 'Lass uns Schritt für Schritt vorgehen' an die Prompt anzufügen. Ein Beispiel: 'Johann passt auf 10 Hunde auf. Jeder Hund benötigt 0.5 Stunden Pflege täglich. Wie viele Stunden verbringt er wöchentlich mit der Pflege der Hunde? Lass uns Schritt für Schritt vorgehen.'",
      },
    ],
    action: "Schließen",
  },
  models: {
    cleared: {
      full: "Die Persona wurde geändert und der Chatverlauf gelöscht!",
      short: "Die Persona wurde geändert!",
    },
    basic: "Persona auswählen",
    persona: "Besondere Persona auswählen",
  },
  start: {
    boxes: [
      {
        id: "start-1",
        title: (
          <>
            <SecurityOutlinedIcon /> Hinweis
          </>
        ),
        text: (
          <>
            Trotz der ergriffenen technischen Sicherungsmaßnahmen bitten wir
            dich, keine <b>personenbezogenen Daten</b>, <b>keine Kundendaten</b>{" "}
            und keine <b>Geschäftsgeheimnisse</b> oder ähnliche Informationen in
            deinen Prompts zu verarbeiten.
          </>
        ),
      },
      {
        id: "start-2",
        title: (
          <>
            <PsychologyIcon /> Persona
          </>
        ),
        text: "Im linken Menü kannst du aus verschiedenen Personas auswählen. In der Hinweisbox darunter erhältst du eine kurze Erklärung zu den Personas.",
      },
      {
        id: "start-3",
        title: (
          <>
            <ThumbUpAltOutlinedIcon /> Feedback
          </>
        ),
        text: "Nach fünf Eingaben erscheint ein Pop-Up, in welchem Ihr in aller Kürze eine Bewertung zu dem Chatbot abgeben könnt.",
        link: "Feedback geben",
        type: "button",
      },
      {
        id: "start-4",
        title: (
          <>
            <PrivacyTipIcon /> Daten
          </>
        ),
        text: "Alle Angaben sind optional. Über den Button auf der linken Seite kannst du uns deine Prompts und deine Angaben zum UB zur Verfügung stellen. Dies hilft uns bei der Analyse des Testlaufs.",
      },
      {
        id: "start-5",
        title: (
          <>
            <AssistantDirectionOutlinedIcon /> Ideen
          </>
        ),
        text: "Falls du Ideen zum starten brauchst, probiere doch Folgendes einmal aus:",
        questions: [
          "Was ist eine Versicherungspolice?",
          "Warum Katzen Musik brauchen: Schreibe 10 Überschriften zu diesem Thema.",
          "Zerlege die Zahl 1234 in ihre Primfaktoren und erläutere den Rechenweg.",
        ],
      },
      {
        id: "start-6",
        title: (
          <>
            <TipsAndUpdatesOutlinedIcon /> Use Cases
          </>
        ),
        text: "Ihr habt Ideen für Use Cases, welche Ihr uns mitteilen wollt? Tragt Sie gerne in unsere Wiki-Seite ein",
        link: "Use Case eintragen",
        target:
          "https://netze.gothaer.de/pages/viewpage.action?pageId=449723609",
        type: "link",
      },
    ],
    examples: [
      {
        id: 1,
        title: "Programmieren",
        text: "Wie kann mich GoChat beim Programmieren/Coding unterstützen? Probier doch mal folgende Prompts aus!",
        image: "/examples_1.png",
        prompts: [
          {
            id: 101,
            text: "Schreibe ein Testskript für folgenden [Sprache] code, für funktionales testing: [Code]",
            prompt:
              "Schreibe ein Testskript für folgenden ##LANGUAGE## code, für funktionales testing: ##CODE##",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Code",
                name: "code",
                placeholder: "##CODE##",
                help: "Hier kannst du deinen Code einfügen.",
              },
            ],
          },
          {
            id: 102,
            text: "Mache ein Review für folgenden [Sprache] code hinsichtlich möglicher Skalierungsprobleme: [Code]",
            prompt:
              "Mache ein Review für folgenden ##LANGUAGE## code hinsichtlich möglicher Skalierungsprobleme: ##CODE##",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Code",
                name: "code",
                placeholder: "##CODE##",
                help: "Hier kannst du deinen Code einfügen.",
              },
            ],
          },
          {
            id: 103,
            text: "Designe einen [Sprache] Algorithmus, der [Problem] löst und folgende [Technik oder Strategie] verwendet.",
            prompt:
              "Designe einen ##LANGUAGE## Algorithmus, der ##PROBLEM## löst und folgende ##TECHNIK## verwendet.",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Problem",
                name: "problem",
                placeholder: "##PROBLEM##",
                help: "Beschreibe oder nenne hier knapp das Problem.",
              },
              {
                type: "free",
                label: "Technik oder Strategie",
                name: "technik",
                placeholder: "##TECHNIK##",
                help: "Trage hier die gewünschte Technik oder Strategie ein.",
              },
            ],
          },
          {
            id: 104,
            text: "Erstelle Vorschläge für das Refactoring des folgenden [Sprache] Codes: [Code]",
            prompt:
              "Erstelle Vorschläge für das Refactoring des folgenden ##LANGUAGE## Codes: ##CODE##",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Code",
                name: "code",
                placeholder: "##CODE##",
                help: "Hier kannst du deinen Code einfügen.",
              },
            ],
          },
          {
            id: 105,
            text: "Übersetze folgenden [Sprache] Code nach [Zielsprache] und verwende [design pattern]: [Code]",
            prompt:
              "Übersetze folgenden ##LANGUAGE## Code nach ##TARGET## und verwende ##PATERN##: ##CODE##",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "code",
                label: "Zielsprache",
                name: "target",
                placeholder: "##TARGET##",
                help: "Nenne eine Zielsprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Design Pattern",
                name: "pattern",
                placeholder: "##PATERN##",
                help: "Nenne das Design Pattern",
              },
              {
                type: "free",
                label: "Code",
                name: "code",
                placeholder: "##CODE##",
                help: "Hier kannst du deinen Code einfügen.",
              },
            ],
          },
          {
            id: 106,
            text: "Erläutere die folgende [Sprache] Funktion: [Code]",
            prompt: "Erläutere die folgende ##LANGUAGE## Funktion: ##CODE##",
            inputs: [
              {
                type: "code",
                label: "Sprache",
                name: "language",
                placeholder: "##LANGUAGE##",
                help: "Nenne eine Sprache oder wähle eine Sprache aus.",
              },
              {
                type: "free",
                label: "Code",
                name: "code",
                placeholder: "##CODE##",
                help: "Hier kannst du deinen Code einfügen.",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        title: "Kommunikation und Bürotätigkeiten",
        text: "Auch bei der täglichen Arbeit und Bürokommunikation unterstützt GoChat bei vielen Tätigkeiten.",
        image: "/examples_2.png",
        prompts: [
          {
            id: 201,
            text: "Erstelle einen Antwortentwurf für folgende E-Mail, der [Thema] beachtet: [E-Mail-Text]",
            prompt:
              "Erstelle einen Antwortentwurf für folgende E-Mail, der ##TOPIC## beachtet: ##MAIL##",
            inputs: [
              {
                type: "free",
                label: "Thema",
                name: "topic",
                placeholder: "##TOPIC##",
                help: "Hier kannst bestimmen, welche Richtung die Antwort nehmen soll: z. B. höfliche Ablehnung",
              },
              {
                type: "free",
                label: "E-Mail Text",
                name: "mail",
                placeholder: "##MAIL##",
                help: "Hier kannst du den E-Mailtext einfügen",
              },
            ],
          },
          {
            id: 202,
            text: "Fasse die Kernaussagen des Textes zusammen: [Text]",
            prompt: "Fasse die Kernaussagen des Textes zusammen: ##TEXT##",
            inputs: [
              {
                type: "free",
                label: "Text",
                name: "text",
                placeholder: "##TEXT##",
                help: "Hier kannst du den Text einfügen.",
              },
            ],
          },
          {
            id: 207,
            text: "Beantworte die folgende Frage [Frage] basierend auf diesem Text: [Text]",
            prompt:
              "Beantworte die folgende Frage: ##QUESTION## basierend auf diesem Text: ##TEXT##",
            inputs: [
              {
                type: "free",
                label: "Frage",
                name: "question",
                placeholder: "##QUESTION##",
                help: "Trage hier die zu beantwortende Frage ein",
              },
              {
                type: "free",
                label: "Text",
                name: "text",
                placeholder: "##TEXT##",
                help: "Füge hier den relevanten Text ein.",
              },
            ],
          },
          {
            id: 203,
            text: "Erstelle eine Powerpoint-Gliederung zu folgendem Thema, gehe besonders auf [Aspekt] ein: [Thema]",
            prompt:
              "Erstelle eine Powerpoint-Gliederung zu folgendem Thema, gehe besonders auf ##TOPIC## ein: ##THEME##",
            inputs: [
              {
                type: "free",
                label: "Aspekt",
                name: "topic",
                placeholder: "##TOPIC##",
                help: "Hier kannst du den inhaltlichen Akzent der Gliederung bestimmen.",
              },
              {
                type: "free",
                label: "Thema",
                name: "theme",
                placeholder: "##THEME##",
                help: "Hier kannst du das Thema deiner Präsentation nennen.",
              },
            ],
          },

          {
            id: 204,
            text: "Erstelle aus folgendem Text Bullet Points mit den wichtigsten Aspekten: [Text]",
            prompt:
              "Erstelle aus folgendem Text Bullet Points mit den wichtigsten Aspekten: ##TEXT##",
            inputs: [
              {
                type: "free",
                label: "Text",
                name: "text",
                placeholder: "##TEXT##",
                help: "Hier kannst du den Text einfügen.",
              },
            ],
          },
          {
            id: 205,
            text: "Übersetze den folgenden Text in Leichte Sprache: [Text]",
            prompt: "Übersetze den folgenden Text in Leichte Sprache: ##TEXT##",
            inputs: [
              {
                type: "free",
                label: "Text",
                name: "text",
                placeholder: "##TEXT##",
                help: "Hier kannst du den Text einfügen.",
              },
            ],
          },
          {
            id: 206,
            text: "Schreibe folgenden Text geschlechtergerecht um: [Text]",
            prompt: "Schreibe folgenden Text geschlechtergerecht um: ##TEXT##",
            inputs: [
              {
                type: "free",
                label: "Text",
                name: "text",
                placeholder: "##TEXT##",
                help: "Hier kannst du den Text einfügen.",
              },
            ],
          },
          {
            id: 208,
            text: "Erstelle drei verschiedene LinkedIn Posts zu folgendem Thema: [Thema]. Gehe dabei besonders auf den Aspekt [Aspekt] ein",
            prompt: "Du arbeitest als Marketing-Experte für eine Versicherung. Erstelle  drei ausführliche LinkedIn Posts zu folgendem Thema: ##THEMA## Berücksichtige dabei besonders den folgenden Aspekt: ##ASPEKT## Erkläre in jedem Post wie der genannte Aspekt bei dem genannten Thema unterstützen kann. Die LinkedIn-Posts sollen die LinkedIn-Formatierung beachten und spannend und informativ geschrieben sein. Verwende Emojis. Zitiere auf keinen Fall Studien oder Untersuchungen. Gib anschauchliche und praktische Tipps für Unternehmen zu dem genannten Thema und Aspekt. Verwende Bullet Points und erstelle für jeden Post passende Hashtags.",
            inputs: [
              {
                type: "free",
                label: "Thema",
                name: "theme",
                placeholder: "##THEMA##",
                help: "Hier kannst du ein Thema für den Post einfügen.",
              },
              {
                type: "free",
                label: "Aspekt",
                name: "aspect",
                placeholder: "##ASPEKT##",
                help: "Hier kannst du ein Aspekt als Schwerpunkt für den Post einfügen.",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        title: "Projektmanagement/Arbeitsablauf",
        text: "Lass Dir bei den lästigen Aufgaben im Rahmen der Arbeitsorganisation oder dem Projektmanagement helfen!",
        image: "/examples_3.png",
        prompts: [
          {
            id: 301,
            text: "Erstelle eine Projektskizze für ein [Thema des Projekts] Projekt.",
            prompt: "Erstelle eine Projektskizze für ein ##TEXT## Projekt.",
            inputs: [
              {
                type: "free",
                label: "Thema",
                name: "text",
                placeholder: "##TEXT##",
                help: "Hier kannst du dein Projektthema nennen.",
              },
            ],
          },
          {
            id: 302,
            text: "Erstelle eine Aufgabenliste/Task list für: [Projekt].",
            prompt: "Erstelle eine Aufgabenliste/Task list für ##PROJECT##.",
            inputs: [
              {
                type: "free",
                label: "Projekt",
                name: "project",
                placeholder: "##PROJECT##",
                help: "Hier kannst du dein Projektthema nennen.",
              },
            ],
          },
          {
            id: 303,
            text: "Priorisiere die folgenden Tasks nach Dringlichkeit: [Tasks]",
            prompt:
              "Priorisiere die folgenden Tasks nach Dringlichkeit ##TASKS##",
            inputs: [
              {
                type: "free",
                label: "Tasks",
                name: "task",
                placeholder: "##TASKS##",
                help: "Hier kannst du deine Taskliste einfügen",
              },
            ],
          },
          {
            id: 304,
            text: "Erstelle einen Entwurf für eine Work Breakdown Structure/Projektstrukturplan mit passenden Arbeitspaketen für ein [Thema] Projekt.",
            prompt:
              "Erstelle einen Entwurf für eine Work Breakdown Structure/Projektstrukturplan mit passenden Arbeitspaketen für ein ##PROJECT## Projekt",
            inputs: [
              {
                type: "free",
                label: "Thema",
                name: "project",
                placeholder: "##PROJECT##",
                help: "Hier kannst du das Thema deines Projektes nennen.",
              },
            ],
          },
          {
            id: 305,
            text: "Erstelle Jira-Tickets für ein [Thema] Projekt jeweils mit User Story und Abnahmekriterien. Gib die Tickets als Markdown-Code aus.",
            prompt:
              "Erstelle Jira Tickets für ein Projekt ##PROJECT## jeweils mit User Story und Abnahmekriterien. Gib die Tickets als Markdown-Code aus.",
            inputs: [
              {
                type: "free",
                label: "Thema",
                name: "project",
                placeholder: "##PROJECT##",
                help: "Hier kannst du das Thema deines Projektes nennen.",
              },
            ],
          },
        ],
      },
    ],
    more: "Beispiele anzeigen",
    try: "Ausprobieren",
  },
  storePrompts: {
    title: "Eingaben für Analysen speichern",
    active: "Danke - deine Eingaben werden für Analysen gespeichert",
    inactive: "Deine Eingaben werden nicht gespeichert",
  },
  login: {
    businessUnits: [
      {
        short: "SAD",
        long: "Außendienst",
      },
      {
        short: "Innen",
        long: "Innendienst",
      },
      {
        short: "Other",
        long: "Keine Angabe",
      },
    ],
    disclaimer: {
      title: (<><b>ACHTUNG!</b></>),
      text: (
        <>
          <b>Die Nutzung von GoChat durch Beschäftigte im Innendienst ist aktuell nicht gestattet!</b>
          <br />
          <b>Eine Vereinbarung mit dem Konzernbetriebsrat zu GoChat konnte bisher nicht abgeschlossen werden;
          insbesondere sind haftungsrechtliche Fragen bisher ungeklärt!</b>
          <br />
        </>
      ),
      confirm: "Habe ich gelesen und verstanden",
    },
    data: {
      title: "Bitte beachte Folgendes zu den Antworten:",
      text: (
        <>
          Der Bot kann (und wird) <b>unpassende</b> und/oder faktisch{" "}
          <b>falsche Ergebnisse</b> ausgeben. Besonders kritisch sind falsche
          Ergebnisse, die nicht ohne vertieftes Fachwissen als solche erkennbar
          sind. Ebenfalls besteht die Möglichkeit, dass der Bot{" "}
          <b>Inhalte erfindet</b> (z. B. Links, Paper).
          <br />
          GoChat ist <b>nicht</b> für die <b>Bearbeitung</b> von Kundenanliegen
          geeignet oder <b>freigegeben</b>. Die Verwendung von GoChat-Inhalten
          in der Kundeninteraktion ist <b>ohne Prüfung</b> durch den jeweiligen
          Mitarbeitenden <b>untersagt.</b>
        </>
      ),
      confirm: "Habe ich gelesen und verstanden",
    },
    gdpr: {
      title: "Bitte beachte Folgendes zum Thema Datenschutz:",
      text: (
        <>
          Es ist Mitarbeiterinnen und Mitarbeitern
          {" "}<b>
            untersagt, Geschäftsgeheimnisse, personenbezogene oder
            personenbeziehbare Daten
          </b>{" "}
          innerhalb dieses Systems <b>einzutragen</b>.
        </>
      ),
      confirm: "Habe ich gelesen, verstanden und befolge ich",
    },
    error: {
      technical:
        "Es ist ein technischer Fehler aufgetreten! Bitte versuche es in ein paar Minuten noch einmal!",
      key: "Bitte Schlüssel prüfen!",
      kbr:
        "Nach einer dreimonatigen Pilotphase musste GoChat für den Innendienst leider inaktiviert werden. Wenn du auf dem Laufenden zu GoChat und Co. gehalten werden möchtest, dann registriere dich für den TechRadar Newsletter im Wiki!",
    },
    validation: {
      gdpr: "Bitte die Datenschutzhinweise lesen, bestätigen und einhalten!",
      security: "Bitte die Sicherheitshinweise lesen und bestätigen!",
      key: "Bitte einen Schlüssel eingeben!",
      business: "Bitte einen Fachbereich oder Sonstiges auswählen!",
    },
    key: "Schlüssel",
    business: "Fachbereich",
    button: "Anmelden",
    poweredBy: "powered by AI",
    url: "https://netze.gothaer.de/pages/viewpage.action?pageId=80183905",
    help: {
      logo: "GoChat Logo",
      start: "Startbild",
    },
  },
};
