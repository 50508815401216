import axios from "axios";
import {Translation} from "../components/Translation";

// send a question, if not empty, to the API
export default function submitQuestion(
    question,
    messages,
    setMessages,
    user,
    setUser,
    notification,
    setNotification,
    configuration,
    navigate,
    setQuestion,
    clearQuestion = true,
    promptId = 0,
    file = null,
    clearUploadedFile = () => {
    }
) {
    if (question === "" || question.trim() === "") {
        setNotification({
            ...notification,
            type: "error",
            show: true,
            message: Translation.submitQuestion.empty,
        });
    } else {
        setNotification({
            ...notification,
            loading: true,
        });

        // Create a FormData object to handle the file upload
        const formData = new FormData();
        formData.append('id', user.id);
        formData.append('session', user.session);
        formData.append('business', user.business);
        formData.append('question', question);
        formData.append('history', JSON.stringify(messages));
        formData.append('model', configuration.model.name);
        formData.append('storePrompts', configuration.storePrompts);
        formData.append('promptId', promptId);

        if (file) {
            formData.append('file', file);
            formData.append('fileName', file.name)
        }

        axios
            .post("/api/ask", formData, {
                headers: {
                    "Custom-Auth-Key": user.token,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data !== undefined) {
                    // store the question as entry and the answer including the current question-answer history with the total tokens
                    setMessages([
                        ...messages,
                        {
                            id: response.data.id,
                            answer: response.data.answer,
                            fileName: response.data.fileName,
                            question: question,
                        },
                    ]);

                    // increase number of asked questions only if tutorial is not active and mark if last prompt was created via prompt generator
                    setUser({
                        ...user,
                        counter:
                            user.startTutorial === false ? user.counter + 1 : user.counter,
                        promptGeneratorUsed: promptId !== 0,
                    });

                    // clear data
                    setNotification({
                        ...notification,
                        loading: false,
                    });

                    if (clearQuestion === true) {
                        setQuestion("");
                    }

                    if (file) {
                        clearUploadedFile(); // Clear the uploaded file after successful submission but store the file name to be shown in the dialog
                    }
                }
            })
            .catch((error) => {
                // don't clear message in case of error
                let errorMessage = Translation.submitQuestion.error;
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        // force login
                        setUser({
                            ...user,
                            authenticated: false,
                            user: null,
                            password: null,
                            key: null,
                        });
                        navigate("/login");
                    } else if (error.response.status === 413) {
                        // backend figured that the page limit has been exceeded
                        clearUploadedFile();
                        errorMessage = Translation.submitQuestion.tooManyPages;
                    } else if (error.response.status === 422) {
                        // backend could not find the model name provided by the frontend
                        errorMessage = Translation.submitQuestion.configError;
                    } else if (error.response.status === 429) {
                        // backend has request rate limit of Azure OpenAI Service reached
                        errorMessage = Translation.submitQuestion.rateLimit;
                    } else if (error.response.status === 503) {
                        // Azure OpenAI API is unavailable
                        errorMessage = Translation.submitQuestion.openAiApiUnavailable;
                    }
                }

                setNotification({
                    ...notification,
                    type: "error",
                    show: true,
                    message: errorMessage,
                    loading: false,
                });
            });
    }
}