import { Translation } from "../components/Translation";

// if the tutorial is started and no question ask, show start message
export default function initTutorial(messages, setMessages, startTutorial) {
  if (startTutorial === true && messages.length === 0) {
    setMessages([
      {
        id: 1,
        answer: Translation.tutorial.initMessages.answer,
        question: Translation.tutorial.initMessages.question,
        history: [],
      },
    ]);
  }
}
