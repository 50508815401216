import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { MessagesContext } from "../context/MessagesContext";
import { NotificationContext } from "../context/NotificationContext";
import { Translation } from "./Translation";

// ask if the chat history should be deleted if persona is changed
export default function ConfirmDialog({ open, setOpen }) {
  const { setMessages } = useContext(MessagesContext);
  const { notification, setNotification } = useContext(NotificationContext);

  // keep current chat history
  const handleCancel = () => {
    setNotification({
      ...notification,
      type: "success",
      show: true,
      message: Translation.models.cleared.short,
      loading: false,
    });

    setOpen(false);
  };

  // delete the current chat history
  const handleClear = () => {
    setMessages([]);

    setNotification({
      ...notification,
      type: "success",
      show: true,
      message: Translation.models.cleared.full,
      loading: false,
    });

    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {Translation.ConfirmDialog.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {Translation.ConfirmDialog.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClear}
            autoFocus
            aria-label={Translation.ConfirmDialog.help.delete}
          >
            {Translation.ConfirmDialog.delete}
          </Button>
          <Button
            onClick={handleCancel}
            aria-label={Translation.ConfirmDialog.help.cancel}
          >
            {Translation.ConfirmDialog.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
