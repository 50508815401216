import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Translation } from "./Translation";
import { useRef, useState } from "react";

// show a card with prompt examples for a given topic
export default function PromptCard({ prompt, tryPrompt }) {
  const scrollRef = useRef();
  const [show, setShow] = useState(
    Translation.start.examples.map((e) => {
      return { id: e.id, open: false };
    })
  );

  // scroll to bottom, if examples are opend and visible
  const scrollDownOrUp = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // toggle showing details for selected type of prompts
  const toggleDetails = (id) => {
    setShow(
      show.map((e) => {
        return e.id === id ? { ...e, open: !e.open } : e;
      })
    );
  };

  return (
    <>
      <Grid
        item
        xs={Translation.start.examples.length === 3 ? 4 : 3}
        sx={{ mb: "20px" }}
      >
        <Paper sx={{ display: "flex", flexDirection: "column" }}>
          <Box key={`promptType-${prompt.id}`} sx={{ display: "flex" }}>
            <CardMedia
              sx={{ width: "240px" }}
              image={prompt.image}
              title={prompt.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {prompt.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {prompt.text}
              </Typography>
              <Button
                size="small"
                onClick={() => toggleDetails(prompt.id)}
                expand={prompt.id}
              >
                {Translation.start.more}
              </Button>
            </CardContent>
          </Box>
          <Collapse
            timeout="auto"
            in={show.find((item) => item.id === prompt.id).open}
            onEntered={scrollDownOrUp}
            unmountOnExit
          >
            <CardContent sx={{ pl: "1px" }}>
              <List>
                {prompt.prompts.map((entry, entryIndex) => (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={entry.text}
                        secondary={
                          <Tooltip
                            key={`promptExample-${prompt.id}-${entryIndex}`}
                            title={Translation.tutorial.tooltip}
                          >
                            <Button
                              type="button"
                              aria-label={Translation.tutorial.tooltip}
                              size="small"
                              onClick={() => tryPrompt(entry)}
                            >
                              {Translation.start.try}
                            </Button>
                          </Tooltip>
                        }
                      />
                    </ListItem>
                  </>
                ))}
              </List>
            </CardContent>
          </Collapse>
        </Paper>

        <div ref={scrollRef} />
      </Grid>
    </>
  );
}
