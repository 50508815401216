import { Grid } from "@mui/material";
import { Translation } from "./Translation";
import { useState } from "react";
import PromptDialog from "./PromptDialog";
import Teaser from "./Teaser";
import PromptCard from "./PromptCard";

// start page with first details regarding the solution
export default function Start() {
  const [showPromptDialog, setShowPromptDialog] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState([]);

  // open prompt dialog window for selected prompt
  const tryPrompt = (prompt) => {
    setCurrentPrompt(prompt);
    setShowPromptDialog(true);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Teaser />
        <Grid container spacing={2} alignItems="baseline">
          {Translation.start.examples.map((e) => {
            return <PromptCard prompt={e} tryPrompt={tryPrompt} />;
          })}
        </Grid>
      </Grid>
      <PromptDialog
        open={showPromptDialog}
        setOpen={setShowPromptDialog}
        prompt={currentPrompt}
        setPrompt={setCurrentPrompt}
      />
    </>
  );
}
