import {IconButton, TextField, Toolbar, Tooltip, Box} from "@mui/material";
import React, {useContext, useRef} from "react";
import {MessagesContext} from "../context/MessagesContext";
import {UserContext} from "../context/UserContext";
import {useNavigate} from "react-router-dom";
import {NotificationContext} from "../context/NotificationContext";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {ConfigurationContext} from "../context/ConfigurationContext";
import {lavender, lightGrey} from "./Color";
import submitQuestion from "../helper/submitQuestion";
import {Translation} from "./Translation";
import {QuestionContext} from "../context/QuestionContext";
import {FileContext} from '../context/FileContext';

// search box to enter question and send it to the AI model
export default function Search() {
    const {question, setQuestion} = useContext(QuestionContext);
    const {messages, setMessages} = useContext(MessagesContext);
    const {user, setUser} = useContext(UserContext);
    const {notification, setNotification} = useContext(NotificationContext);
    const {configuration} = useContext(ConfigurationContext);
    const navigate = useNavigate();

    const {
        uploadedFileName,
        setUploadedFileName,
        uploadedFile,
        setUploadedFile,
        fileSent,
        setFileSent,
        handleFileDelete
    } = useContext(FileContext);

    const fileInputRef = useRef(null);

    const clearUploadedFile = () => {
        setUploadedFileName("");
        setUploadedFile(null);
        setFileSent(true);
    };

    // send the string when Enter is pressed
    const handleChange = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();

            submitQuestion(
                question,
                messages,
                setMessages,
                user,
                setUser,
                notification,
                setNotification,
                configuration,
                navigate,
                setQuestion,
                true,
                0,
                uploadedFile,
                clearUploadedFile
            );
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
            if (allowedTypes.includes(file.type)) {
                setUploadedFileName(file.name);
                setUploadedFile(file);
                setFileSent(false);
            } else {
                // Show notification or alert that only allowed file types are accepted
                alert(Translation.search.wrongFileType);
                event.target.value = null; // Reset the file input
            }
        }
    };

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                if (file) {
                    setUploadedFileName(file.name);
                    setUploadedFile(file);
                    setFileSent(false);
                }
            }
        }
    };

    return (
        <>
            <Toolbar sx={{backgroundColor: lightGrey}}>
                <Box sx={{width: '100%'}}>
                    <TextField
                        id="question"
                        name="question"
                        label={Translation.search.field}
                        aria-label={Translation.search.field}
                        variant="standard"
                        value={question}
                        onChange={(event) => setQuestion(event.target.value)}
                        onKeyUp={(event) => handleChange(event)}
                        onPaste={(event) => handlePaste(event)}
                        fullWidth
                        autoFocus
                        multiline
                    />
                </Box>
                {(
                    <>
                        <input
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{display: 'none'}}
                            id="icon-button-file"
                            type="file"
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                        />
                        <label htmlFor="icon-button-file">
                            <Tooltip title={uploadedFileName ? uploadedFileName : Translation.search.upload}>
                                <IconButton
                                    color="primary"
                                    className="uploadButton"
                                    aria-label="upload file"
                                    component="span"
                                >
                                    {uploadedFileName ? <CheckCircleIcon/> : <AttachFileIcon/>}
                                </IconButton>
                            </Tooltip>
                        </label>
                    </>
                )}
                {!fileSent && uploadedFileName && (
                    <Tooltip title={Translation.search.delete}>
                        <IconButton
                            color="secondary"
                            aria-label="delete file"
                            onClick={handleFileDelete}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}
                <IconButton
                    type="button"
                    className="sentButton"
                    sx={{p: "10px"}}
                    aria-label={Translation.search.button}
                    onClick={() => {
                        submitQuestion(
                            question,
                            messages,
                            setMessages,
                            user,
                            setUser,
                            notification,
                            setNotification,
                            configuration,
                            navigate,
                            setQuestion,
                            true,
                            0,
                            uploadedFile,
                            clearUploadedFile
                        );
                    }}
                >
                    <SendIcon sx={{color: lavender}}/>
                </IconButton>
            </Toolbar>
        </>
    );
}