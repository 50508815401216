import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";

// show notifications at the top center or a black loading overlay
export default function Notification() {
  const { notification, setNotification } = useContext(NotificationContext);

  // close the toast notification
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ ...notification, show: false });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={notification.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        open={notification.show}
        onClose={handleClose}
      >
        <Alert severity={notification.type} onClose={handleClose}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
}
