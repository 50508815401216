import { Button, Tooltip } from "@mui/material";

// Navigation buttons with text label
export default function NavButton({ translation, action, icon }) {
  return (
    <>
      <Tooltip title={translation.help}>
        <Button
          size="small"
          color="inherit"
          onClick={action}
          aria-label={translation.help}
          startIcon={icon}
        >
          {translation.label}
        </Button>
      </Tooltip>
    </>
  );
}
