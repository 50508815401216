import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useState } from "react";
import { MessagesContext } from "../context/MessagesContext";
import { NotificationContext } from "../context/NotificationContext";
import { UserContext } from "../context/UserContext";
import { Translation } from "./Translation";
import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfigurationContext } from "../context/ConfigurationContext";
import submitQuestion from "../helper/submitQuestion";
import { QuestionContext } from "../context/QuestionContext";

// delimter around all entered values
const delimiter = "``";
const languages = ["Java", "Python", "VBA", "JavaScript", "SAS", "Cobol"];

// dialog to clear the message history and create a new session
export default function PromptDialog({ open, setOpen, prompt }) {
  const { setQuestion } = useContext(QuestionContext);
  const { messages, setMessages } = useContext(MessagesContext);
  const { user, setUser } = useContext(UserContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const { configuration } = useContext(ConfigurationContext);
  const navigate = useNavigate();
  const [values, setValues] = useState([]);

  // close dialog
  const handleCancel = () => {
    setOpen(false);
  };

  // create a prompt based on the entered values and the prompt template
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // replace the placeholder elements with the entered values
    let error = false;
    let promptText = prompt.prompt;
    prompt.inputs.forEach((e) => {
      if (values[e.name] === undefined || values[e.name].trim() === "") {
        error = true;
      } else {
        promptText = promptText.replace(
          e.placeholder,
          delimiter + values[e.name].trim() + delimiter
        );
      }
    });

    // check that all fields are filled
    if (error === true) {
      setNotification({
        ...notification,
        type: "error",
        show: true,
        message: Translation.PromptDialog.error,
      });
      return;
    }

    // submit pre-configured and custom filled prompt
    submitQuestion(
      promptText,
      messages,
      setMessages,
      user,
      setUser,
      notification,
      setNotification,
      configuration,
      navigate,
      setQuestion,
      true,
      prompt.id
    );
    setOpen(false);
  };

  // store the entered value
  const updateValue = (field, newValue) => {
    let copyValues = values;
    copyValues[field] = newValue;
    setValues(copyValues);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translation.PromptDialog.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle2" gutterBottom>
              {prompt.text}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {Translation.PromptDialog.body}
            </Typography>
            {prompt.inputs !== undefined ? (
              <>
                <Stack spacing={1}>
                  <FormControl>
                    {prompt.inputs.map((field, fieldIndex) => {
                      if (field.type === "code") {
                        return (
                          <>
                            <Autocomplete
                              freeSolo
                              required
                              autoFocus={fieldIndex === 0}
                              options={languages}
                              onChange={(event, newValue) => {
                                updateValue(field.name, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={field.label}
                                  name={field.name}
                                  helperText={field.help}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                  }}
                                  variant="standard"
                                  autoFocus={fieldIndex === 0}
                                  required
                                />
                              )}
                            />
                          </>
                        );
                      }
                      return (
                        <>
                          <TextField
                            id={field.name}
                            label={field.label}
                            defaultValue={""}
                            helperText={field.help}
                            autoFocus={fieldIndex === 0}
                            onChange={(event) =>
                              updateValue(field.name, event.target.value)
                            }
                            variant="standard"
                            required
                            multiline
                          />
                        </>
                      );
                    })}
                  </FormControl>
                </Stack>
              </>
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>{Translation.PromptDialog.try}</Button>
          <Button onClick={handleCancel} autoFocus>
            {Translation.PromptDialog.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
