// update the user context and store selected values within the local storage
export default function updateUserContext(user, setUser, updates) {
  const updatedUser = { ...user, ...updates };
  setUser(updatedUser);

  // store selected values within the local storage of the browser to reuse them after a page reload
  localStorage.setItem(
    "configuration",
    JSON.stringify({
      feedbackGiven: updatedUser.feedbackGiven,
      showIntro: updatedUser.showIntro,
      startTutorial: updatedUser.startTutorial,
      currentTutorialStep: updatedUser.currentTutorialStep,
      feedbackTutorial: updatedUser.feedbackTutorial,
    })
  );
}
