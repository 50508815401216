import {Grid, IconButton, Paper, Tooltip} from "@mui/material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import React, {useContext} from "react";
import {UserContext} from "../context/UserContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {NotificationContext} from "../context/NotificationContext";
import ReactMarkdown from "react-markdown";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssistantIcon from '@mui/icons-material/Assistant';
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {lavender, darkBlue} from "./Color";
import {Translation} from "./Translation";
import remarkGfm from "remark-gfm";

// configure default CSS values
const defaultBorderRadius = "25px";
const defaultMargin = "25px";
const defaultSize = 11;


// show a question and the corresponding answer
export default function Result({data}) {
    const {user, setUser} = useContext(UserContext);
    const {notification, setNotification} = useContext(NotificationContext);
    const navigate = useNavigate();

    // send data and rating to API
    const sendRating = (data, rating) => {
        axios
            .post(
                "/api/rate",
                {
                    id: data.id,
                    answer: data.answer,
                    question: data.question,
                    rating: rating,
                },
                {
                    headers: {
                        "Custom-Auth-Key": user.token,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    setNotification({
                        ...notification,
                        type: "success",
                        show: true,
                        message: Translation.result.success,
                    });
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        // force login
                        setUser({
                            ...user,
                            authenticated: false,
                            user: null,
                            password: null,
                            key: null,
                        });
                        navigate("/login");
                    }
                }
            });
    };

    // copy to clipboard and show notification via toast
    const copyToClipboard = (answer, type) => {
        navigator.clipboard.writeText(answer);
        setNotification({
            ...notification,
            type: "info",
            show: true,
            message:
                type === "code"
                    ? Translation.result.copy.code
                    : Translation.result.copy.text,
        });
    };

    return (
        <>
            <Grid
                container
                direction="row"
                sx={{display: "flex", justifyContent: "flex-end"}}
            >
                <Grid item xs={defaultSize} md={defaultSize} lg={defaultSize}>
                    <Card
                        sx={{
                            mt: defaultMargin,
                            borderRadius: defaultBorderRadius,
                        }}
                    >
                        <CardHeader
                            sx={{
                                bgcolor: darkBlue,
                                color: "white",
                                pt: "3px",
                                pb: "3px",
                            }}
                            avatar={<AccountBoxIcon/>}
                            title={
                                <>
                                    <Typography variant="h6">
                                        {Translation.result.question}
                                    </Typography>
                                </>
                            }
                        />
                        <CardContent sx={{mb: 0, pb: 0}}>
                            {/* if data.fileName is defined in the backends response a paper elevation element with the fileName is shown in the dialog card of the user */}
                            {data.fileName !== null && (
                                <>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            display: 'inline-block',
                                            padding: '4px 8px',
                                            marginTop: '8px',
                                            marginBottom: '8px',
                                            backgroundColor: '#e8f5e9',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Typography variant="caption" sx={{color: 'green'}}>
                                            {data.fileName}
                                        </Typography>
                                    </Paper>
                                    <div style={{marginBottom: '16px'}}></div>
                                </>
                            )}
                            {data.question}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                sx={{display: "flex", justifyContent: "flex-start"}}
            >
                <Grid item xs={defaultSize} md={defaultSize} lg={defaultSize}>
                    <Card
                        sx={{
                            mt: defaultMargin,
                            borderRadius: defaultBorderRadius,
                        }}
                    >
                        <CardHeader
                            sx={{
                                bgcolor: lavender,
                                color: "white",
                                pt: "3px",
                                pb: "3px",
                            }}
                            avatar={<AssistantIcon/>}
                            title={
                                <>
                                    <Typography variant="h6">
                                        {Translation.result.answer}
                                    </Typography>
                                </>
                            }
                        />

                        <CardContent sx={{mt: 0, pt: 0, pb: 0}}>
                            <ReactMarkdown
                                children={data.answer}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({node, inline, className, children, ...props}) {
                                        const match = /language-(\w+)/.exec(className || "");
                                        return !inline && match ? (
                                            <>
                                                <SyntaxHighlighter
                                                    {...props}
                                                    children={String(children).replace(/\n$/, "")}
                                                    language={match[1]}
                                                    PreTag="div"
                                                />
                                                <Tooltip title={Translation.result.copy.title}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            copyToClipboard(
                                                                String(children).replace(/\n$/, ""),
                                                                "code"
                                                            )
                                                        }
                                                    >
                                                        <ContentPasteIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            />
                        </CardContent>
                        <CardActions disableSpacing>
                            <Tooltip title={Translation.result.feedback.negativ}>
                                <IconButton size="small" onClick={() => sendRating(data, -1)}>
                                    <ThumbDownAltIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={Translation.result.feedback.positiv}>
                                <IconButton size="small" onClick={() => sendRating(data, 1)}>
                                    <ThumbUpAltIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={Translation.result.copy.action}>
                                <IconButton
                                    size="small"
                                    onClick={() => copyToClipboard(data.answer)}
                                >
                                    <ContentPasteIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
