import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { Translation } from "./Translation";
import { Button, Collapse } from "@mui/material";
import { useRef } from "react";
import { useState } from "react";

// show a teaser block with background image and more details on start page
export default function Teaser() {
  const scrollRef = useRef();
  const [more, setMore] = useState(false);

  // scroll to bottom, if examples are opend and visible
  const scrollDownOrUp = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Paper
      sx={{
        width: "100%",
        mb: 7,
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          backgroundColor: "grey.800",
          color: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(/teaser.png)`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,.2)",
          }}
          elevation={0}
        />
        <Grid container>
          <Grid item md={8}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 3, md: 6 },
              }}
            >
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {Translation.claim.main}
              </Typography>
              <Typography
                component="h1"
                variant="h4"
                color="inherit"
                gutterBottom
              >
                {Translation.claim.sub}
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                {Translation.claim.teaser}
              </Typography>

              <Button onClick={() => setMore(!more)} expand={more}>
                {Translation.claim.continue}
              </Button>
              <Link variant="subtitle1" href="#"></Link>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Collapse
        in={more}
        timeout="auto"
        onEntered={scrollDownOrUp}
        unmountOnExit
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
            pl: { xs: 3, md: 6 },
            pt: { xs: 2, md: 2 },
            pr: { xs: 3, md: 6 },
          }}
        >
          <Typography variant="body1" color="inherit" paragraph>
            {Translation.claim.long}
          </Typography>
        </Box>
      </Collapse>
      <div ref={scrollRef} />
    </Paper>
  );
}
