import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HelpIcon from "@mui/icons-material/Help";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import TourIcon from "@mui/icons-material/Tour";
import Configuration from "../components/Configuration";
import { Button, Container, Grid, Tooltip } from "@mui/material";
import Start from "../components/Start";
import History from "../components/History";
import { MessagesContext } from "../context/MessagesContext";
import Search from "../components/Search";
import Notification from "../components/Notification";
import { useContext, useEffect, useState } from "react";
import Help from "../components/Help";
import ClearDialog from "../components/ClearDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import RatingDialog from "../components/RatingDialog";
import StarRateIcon from "@mui/icons-material/StarRate";
import { UserContext } from "../context/UserContext";
import { darkBlue } from "../components/Color";
import { Translation } from "../components/Translation";
import updateUserContext from "../helper/updateUserContext";
import initTutorial from "../helper/initTutorial";
import { useTour } from "@reactour/tour";
import NavButton from "../components/NavButton";
import UndoIcon from "@mui/icons-material/Undo";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// default main page
export default function BasicPage() {
  const [open, setOpen] = useState(false);
  const { messages, setMessages } = useContext(MessagesContext);
  const { user, setUser } = useContext(UserContext);
  const [help, setHelp] = useState(false);
  const [clear, setClear] = useState(false);
  const [rating, setRating] = useState(false);
  const { currentStep, setIsOpen } = useTour();

  // show configuration menu
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // close configuration menu
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // show help dialog
  const showHelp = () => {
    setHelp(true);
  };

  // show clear chat history dialog
  const showClear = () => {
    setClear(true);
  };

  // show rating option if user has not give a feedback
  const showRating = () => {
    setRating(true);
  };

  // toggle the tutorial
  const toggleTutorial = (toggle = null) => {
    const toggleTutorial = toggle === null ? !user.startTutorial : toggle;

    // update the user preferences
    updateUserContext(user, setUser, { startTutorial: toggleTutorial });

    // add empty messages to history if no message has been send
    initTutorial(messages, setMessages, toggleTutorial);

    // stop tour if open
    setIsOpen(false);
  };

  // open url in new tab for adding use case ideas
  const openUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  useEffect(() => {
    // add as last step the question if the tutorial should be started including a button to start the tutorial
    if (Translation.intro[Translation.intro.length - 1].last === undefined) {
      Translation.intro.push({
        last: true,
        position: "center",
        open: false,
        content: (
          <>
            {Translation.tutorial.question}
            <br />
            <Button
              onClick={() => toggleTutorial(true)}
              variant="contained"
              startIcon={<WavingHandIcon />}
            >
              {Translation.tutorial.start}
            </Button>
          </>
        ),
      });
    }

    // show intro at first start of app
    if (user.showIntro === true) {
      setIsOpen(true);
      updateUserContext(user, setUser, { showIntro: false });
    }
    // eslint-disable-next-line
  }, []);

  // open and close menu if corresponding intro step is reached
  useEffect(() => {
    setOpen(Translation.intro[currentStep].open);
  }, [currentStep]);

  return (
    <Box sx={{ display: "flex" }}>
      <Notification />
      <Help open={help} setOpen={setHelp} />
      <ClearDialog open={clear} setOpen={setClear} />
      <RatingDialog open={rating} setOpen={setRating} />

      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ bgcolor: "#ffffff", color: darkBlue }}
      >
        <Toolbar>
          <Tooltip title={Translation.navigation.config.help}>
            <IconButton
              color="inherit"
              aria-label={Translation.navigation.config.help}
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
              className="burgerMenu"
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {Translation.title}
          </Typography>
          <div className="naviMenu">
            {user.feedbackGiven === false ? (
              <>
                <NavButton
                  translation={Translation.navigation.feedback}
                  action={showRating}
                  icon={<StarRateIcon />}
                />
              </>
            ) : (
              ""
            )}
            {user.promptGeneratorUsed === true && messages.length > 0 ? (
              <>
                <NavButton
                  translation={Translation.navigation.generator}
                  icon={<UndoIcon />}
                  action={() => setMessages([])}
                />
              </>
            ) : (
              ""
            )}
            {messages.length > 0 ? (
              <>
                <NavButton
                  translation={Translation.navigation.clear}
                  action={showClear}
                  icon={<DeleteIcon />}
                />
              </>
            ) : (
              ""
            )}
            <NavButton
              translation={Translation.navigation.idea}
              icon={<TipsAndUpdatesOutlinedIcon />}
              action={() => openUrl(Translation.configuration.url)}
            />
            <NavButton
              translation={Translation.navigation.tour}
              action={() => setIsOpen(true)}
              icon={<TourIcon />}
            />
            <NavButton
              translation={Translation.navigation.tutorial}
              action={() => toggleTutorial()}
              icon={<WavingHandIcon />}
            />
            <NavButton
              translation={Translation.navigation.help}
              action={showHelp}
              icon={<HelpIcon />}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            aria-label={Translation.navigation.close.help}
          >
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Configuration />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Grid container spacing={3} sx={{ p: 1 }}>
          {messages.length === 0 ? (
            <Start setOpenFeedback={setRating} />
          ) : (
            <History />
          )}
        </Grid>
        <Container
          sx={{ m: 4, alignItems: "center", alignContent: "center" }}
        ></Container>
      </Main>
      <AppBar
        position="fixed"
        open={open}
        sx={{ top: "auto", bottom: 0, bgcolor: "white" }}
      >
        <Search />
      </AppBar>
    </Box>
  );
}
