import { Box, Grid } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { MessagesContext } from "../context/MessagesContext";
import Result from "./Result";
import Tutorial from "./Tutorial";
import { UserContext } from "../context/UserContext";

// show an overview of all question-answer pairs currently send
export default function History() {
  const scrollReference = useRef();
  const { messages } = useContext(MessagesContext);
  const { user } = useContext(UserContext);

  // scroll to answer at bottom
  useEffect(() => {
    scrollReference.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <Box sx={{ width: "100%", mb: "25px" }}>
        <Grid container spacing={2} direction="row">
          <Grid
            item
            xs={user.startTutorial === true ? 6 : 12}
            md={user.startTutorial === true ? 8 : 12}
            lg={user.startTutorial === true ? 8 : 12}
          >
            {messages.map((data) => {
              return <Result data={data} />;
            })}
            <div ref={scrollReference} />
          </Grid>
          {user.startTutorial === true ? (
            <Grid item xs={6} md={4} lg={4}>
              <Tutorial />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </>
  );
}
