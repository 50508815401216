import {useEffect, useState} from "react";
import Paths from "./Routes";
import {UserContext} from "./context/UserContext";
import {ConfigurationContext} from "./context/ConfigurationContext";
import {MessagesContext} from "./context/MessagesContext";
import {FileContext} from "./context/FileContext";
import {NotificationContext} from "./context/NotificationContext";
import initTutorial from "./helper/initTutorial";
import {TourProvider} from "@reactour/tour";
import {Translation} from "./components/Translation";
import {QuestionContext} from "./context/QuestionContext";

function App() {
    // User data
    const [user, setUser] = useState({
        authenticated: false,
        user: null,
        password: null,
        session: null,
        feedbackGiven: null,
        showIntro: true,
        startTutorial: false,
        currentTutorialStep: 0,
        feedbackTutorial: false,
        promptGeneratorUsed: false,
    });

    // list of supported models, hint if data should be stored
    const [configuration, setConfiguration] = useState({
        models: [],
        storePrompts: false,
    });

    // list all prompts and answers
    const [messages, setMessages] = useState([]);

    // loading and notification configuration
    const [notification, setNotification] = useState({
        loading: false,
        show: false,
        type: "info",
        message: null,
    });

    // global access to search question input
    const [question, setQuestion] = useState("");

    // global access to file handler
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileSent, setFileSent] = useState(false);
    const handleFileDelete = () => {
        setUploadedFileName("");
        setUploadedFile(null);
        setFileSent(false);
    };

    // load selected user configuration settings from the local storage
    useEffect(() => {
        const storedInformation = JSON.parse(localStorage.getItem("configuration"));
        if (storedInformation !== undefined && storedInformation !== null) {
            let loadedUser = {...user};
            [
                "currentTutorialStep",
                "feedbackGiven",
                "showIntro",
                "startTutorial",
                "feedbackTutorial",
            ].forEach((key) => {
                if (
                    storedInformation[key] !== undefined &&
                    storedInformation[key] !== null
                ) {
                    loadedUser[key] = storedInformation[key];
                }
            });
            setUser(loadedUser);

            // if tutorial has been active last time restart it
            if (loadedUser.startTutorial === true) {
                initTutorial(messages, setMessages, true);
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <TourProvider
                steps={Translation.intro}
                styles={{popover: (base) => ({...base, maxWidth: 600})}}
            >
                <UserContext.Provider value={{user, setUser}}>
                    <ConfigurationContext.Provider
                        value={{configuration, setConfiguration}}
                    >
                        <MessagesContext.Provider value={{messages, setMessages}}>
                            <FileContext.Provider value={{
                                uploadedFileName,
                                setUploadedFileName,
                                uploadedFile,
                                setUploadedFile,
                                fileSent,
                                setFileSent,
                                handleFileDelete
                            }}>
                                <NotificationContext.Provider value={{notification, setNotification}}>
                                    <QuestionContext.Provider value={{question, setQuestion}}>
                                        {Paths()}
                                    </QuestionContext.Provider>
                                </NotificationContext.Provider>
                            </FileContext.Provider>
                        </MessagesContext.Provider>
                    </ConfigurationContext.Provider>
                </UserContext.Provider>
            </TourProvider>
        </>
    );
}

export default App;
