import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useContext, useState } from "react";
import { ConfigurationContext } from "../context/ConfigurationContext";
import { NotificationContext } from "../context/NotificationContext";
import { MessagesContext } from "../context/MessagesContext";
import { UserContext } from "../context/UserContext";
import { Translation } from "./Translation";
import ConfirmDialog from "./ConfirmDialog";

// show all available models and select one as default one
export default function Models() {
  const { configuration, setConfiguration } = useContext(ConfigurationContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const { messages } = useContext(MessagesContext);
  const [value, setValue] = useState(configuration.model.id);
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  // mark the selected model as the default one
  const changeModel = (event) => {
    const newModel = configuration.models.filter(
      (e) => parseInt(e.id) === parseInt(event.target.value)
    )[0];
    setValue(newModel.id);
    setConfiguration({ ...configuration, model: newModel });

    // if tutorial is active history gets never cleaned
    if (user.startTutorial === true || messages.length === 0) {
      setNotification({
        ...notification,
        type: "success",
        show: true,
        message: Translation.models.cleared.short,
        loading: false,
      });
    } else {
      // open dialog and ask if history should be deleted
      setOpen(true);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel id="basic-models">{Translation.models.basic}</FormLabel>
        <RadioGroup
          aria-labelledby="asic-models"
          name="models-group"
          value={value}
          onChange={changeModel}
        >
          {configuration.models.map((e) => {
            if (e.persona === false) {
              return (
                <FormControlLabel
                  key={e.id}
                  value={e.id}
                  control={<Radio />}
                  label={e.name}
                />
              );
            } else {
              return "";
            }
          })}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="advanced-models">{Translation.models.persona}</FormLabel>
        <RadioGroup
          aria-labelledby="advanced-models"
          name="models-group"
          value={value}
          onChange={changeModel}
        >
          {configuration.models.map((e) => {
            if (
              e.persona === true &&
              (e.feedback_required === false || user.feedbackGiven === true)
            ) {
              return (
                <FormControlLabel
                  key={e.id}
                  value={e.id}
                  control={<Radio />}
                  label={e.name}
                />
              );
            } else {
              return "";
            }
          })}
        </RadioGroup>
      </FormControl>
      <ConfirmDialog open={open} setOpen={setOpen} />
    </>
  );
}
